/* ==============
  Range slider
===================*/
.irs-from, .irs-to, .irs-single {
  background: $primary;
}

.irs-from:after, .irs-to:after, .irs-single:after {
  border-top-color: $primary;
}

.irs--round .irs-bar,
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child,
.irs--flat .irs-handle > i:first-child,
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar {
  background-color: $primary;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  border-top-color: $primary;
}

.irs--sharp .irs-bar,
.irs--sharp .irs-handle {
  background-color: $success;
}

.irs--sharp .irs-handle > i:first-child {
  border-top-color: $success;
}

.irs--sharp .irs-line {
  background-color: $gray-300;
}

.irs--square .irs-handle {
  border-color: $blue;
}

.irs--square .irs-from,
.irs--square .irs-to,
.irs--square .irs-single,
.irs--square .irs-bar {
  background-color: $blue;
}


.irs--flat .irs-min, .irs--flat .irs-max,
.irs--round .irs-min, .irs--round .irs-max,
.irs--square .irs-min, .irs--square .irs-max,
.irs--modern .irs-min, .irs--modern .irs-max {

  top: 0;
  font-size: 12px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  color: $gray-700;
  background-color: $gray-200;
  border-radius: 5px;

}

.irs--modern .irs-from,
.irs--modern .irs-to,
.irs--modern .irs-single,
.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
  font-size: 11px;
  line-height: 1.333;
  text-shadow: none;
  padding: 2px 5px;
  background-color: $primary;
  color: $white;
  border-radius: 5px;
}

.irs--modern .irs-from:before,
.irs--modern
.irs-to:before,
.irs--modern .irs-single:before,
.irs--round .irs-from:before,
.irs--round .irs-to:before,
.irs--round .irs-single:before {
  content: "";
  border: 3px solid transparent;
  border-top-color: $primary;
}

.irs--round,
.irs--square,
.irs--flat,
.irs--modern {
  .irs-line {
    background: linear-gradient(to bottom, $gray-300 0%, $gray-200 100%);
    border: 1px solid $border;
  }
}

.irs--sharp,
.irs--flat,
.irs--square,
.irs--modern {
  .irs-grid-pol {
    background-color: $gray-400;
  }
}

.irs--square .irs-handle,
.irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
  background-color: $card-bg;
}

.irs--modern .irs-bar {
  background: $primary;
  background: linear-gradient(135deg, rgba($primary, 0.5) 0%, $primary 36%, darken($primary, 5%) 61%, rgba($primary, 0.5) 100%);
}

.irs--round .irs-handle {
  border: 4px solid $primary;
  background-color: transparent;
  box-shadow: 0 1px 3px rgba($primary, 0.3);
}

.showcase__mark {
  display: block;
  position: absolute;
  top: 35px;
  background: $danger;
  transform: rotate(-45deg);
  padding: 1px 3px;
  border-radius: 3px;
  color: $white;
  margin-left: -18px;
}

.irs--modern .irs-handle > i:nth-child(1) {
  width: 8px;
  height: 8px;
}

.irs--modern .irs-handle > i:nth-child(1),
.irs--modern .irs-handle > i:nth-child(2) {
  background: $card-bg;
}

.irs--round .irs-line,
.irs--square .irs-line,
.irs--flat .irs-line,
.irs--modern .irs-line {
  box-shadow: $box-shadow;
}