//
// Check Box Radio Button
// 

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  border-color: $white;
}

.radio .custom-control-label::after {
  position: absolute;
  top: 7px;
}


.checkbox {
  //padding-left: 20px;
  label {
    display: inline-block;
    padding-left: 8px;
    position: relative;
    font-weight: normal;

    &::before {
      -o-transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      background-color: $card-bg;
      border-radius: 3px;
      border: 2px solid $text-muted;
      content: "";
      display: inline-block;
      height: 18px;
      left: 0;
      top: 0px;
      margin-left: -18px;
      position: absolute;
      transition: 0.3s ease-in-out;
      width: 18px;
      outline: none !important;
    }

    &::after {
      color: $light;
      display: inline-block;
      font-size: 11px;
      height: 18px;
      left: 0;
      margin-left: -18px;
      padding-left: 3px;
      padding-top: 2px;
      position: absolute;
      width: 18px;
    }
  }

  input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;

    &:disabled + label {
      opacity: 0.65;
    }
  }

  input[type="checkbox"]:focus + label {
    &::before {
      outline-offset: -2px;
      outline: none;
    }
  }

  input[type="checkbox"]:checked + label {
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 7px;
      display: table;
      width: 5px;
      height: 10px;
      border: 2px solid $dark;
      border-top-width: 0;
      border-left-width: 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input[type="checkbox"]:disabled + label {
    &::before {
      background-color: $light;
      cursor: not-allowed;
    }
  }
}

.checkbox.checkbox-circle {
  label {
    &::before {
      border-radius: 50%;
    }
  }
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox.checkbox-single {
  input {
    height: 18px;
    width: 18px;
    position: absolute;
  }

  label {
    height: 18px;
    width: 18px;

    &:before {
      margin-left: 0;
    }

    &:after {
      margin-left: 0;
    }
  }
}


.checkbox-primary {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $primary;
      border-color: $primary;
    }

    &::after {
      border-color: $white;
    }
  }
}

.checkbox-danger {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $danger;
      border-color: $danger;
    }

    &::after {
      border-color: $white;
    }
  }
}

.checkbox-info {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $info;
      border-color: $info;
    }

    &::after {
      border-color: $white;
    }
  }
}

.checkbox-warning {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $warning;
      border-color: $warning;
    }

    &::after {
      border-color: $white;
    }
  }
}

.checkbox-success {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $success;
      border-color: $success;
    }

    &::after {
      border-color: $white;
    }
  }
}

.checkbox-purple {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $purple;
      border-color: $purple;
    }

    &::after {
      border-color: $white;
    }
  }
}

.checkbox-pink {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $pink;
      border-color: $pink;
    }

    &::after {
      border-color: $white;
    }
  }
}

.checkbox-dark {
  input[type="checkbox"]:checked + label {
    &::before {
      background-color: $dark;
      border-color: $dark;
    }

    &::after {
      border-color: $white;
    }
  }
}


/* Radios */

.radio {
  //padding-left: 20px;
  label {
    display: inline-block;
    padding-left: 8px;
    position: relative;
    font-weight: normal;

    &::before {
      -o-transition: border 0.5s ease-in-out;
      -webkit-transition: border 0.5s ease-in-out;
      background-color: $card-bg;
      border-radius: 50%;
      border: 2px solid $text-muted;
      content: "";
      display: inline-block;
      height: 18px;
      left: 0;
      top: 0px;
      margin-left: -18px;
      position: absolute;
      transition: border 0.5s ease-in-out;
      width: 18px;
      outline: none !important;
    }

    &::after {
      -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -ms-transform: scale(0, 0);
      -o-transform: scale(0, 0);
      -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      -webkit-transform: scale(0, 0);
      -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      background-color: $dark;
      border-radius: 50%;
      content: " ";
      display: inline-block;
      height: 10px;
      left: 6px;
      margin-left: -20px;
      position: absolute;
      top: 4px;
      transform: scale(0, 0);
      transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      width: 10px;
    }
  }

  input[type="radio"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;

    &:disabled + label {
      opacity: 0.65;
    }
  }

  input[type="radio"]:focus + label {
    &::before {
      outline-offset: -2px;
      outline: 5px auto -webkit-focus-ring-color;
      outline: thin dotted;
    }
  }

  input[type="radio"]:checked + label {
    &::after {
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }

  input[type="radio"]:disabled + label {
    &::before {
      cursor: not-allowed;
    }
  }
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single {
  label {
    height: 17px;
  }
}


.radio-primary {
  input[type="radio"] + label {
    &::after {
      background-color: $primary;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $primary;
    }

    &::after {
      background-color: $primary;
    }
  }
}

.radio-danger {
  input[type="radio"] + label {
    &::after {
      background-color: $danger;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $danger;
    }

    &::after {
      background-color: $danger;
    }
  }
}

.radio-info {
  input[type="radio"] + label {
    &::after {
      background-color: $info;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $info;
    }

    &::after {
      background-color: $info;
    }
  }
}

.radio-warning {
  input[type="radio"] + label {
    &::after {
      background-color: $warning;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $warning;
    }

    &::after {
      background-color: $warning;
    }
  }
}

.radio-success {
  input[type="radio"] + label {
    &::after {
      background-color: $success;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $success;
    }

    &::after {
      background-color: $success;
    }
  }
}

.radio-purple {
  input[type="radio"] + label {
    &::after {
      background-color: $purple;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $purple;
    }

    &::after {
      background-color: $purple;
    }
  }
}

.radio-pink {
  input[type="radio"] + label {
    &::after {
      background-color: $pink;
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: $pink;
    }

    &::after {
      background-color: $pink;
    }
  }
}


.radio2 {
  label {
    display: inline-block;
    padding-left: 8px;
    position: relative;
    font-weight: normal;
    margin-bottom: 10px;

    &::before {
      background-color: $white;
      border-radius: 50%;
      border: 2px solid transparent;
      content: "";
      display: inline-block;
      height: 16px;
      top: -1px;
      left: -2px;
      margin-left: -18px;
      position: absolute;
      width: 16px;
      outline: none !important;
    }

    &::after {
      border-radius: 50%;
      content: " ";
      display: inline-block;
      height: 8px;
      left: 4px;
      margin-left: -20px;
      position: absolute;
      top: 3px;
      transform: scale(0, 0);
      width: 8px;
    }
  }

  input[type="radio"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    outline: none !important;

    &:disabled + label {
      opacity: 0.65;
    }
  }

  input[type="radio"]:focus + label {
    &::before {
      outline-offset: -2px;
      outline: 5px auto -webkit-focus-ring-color;
      outline: thin dotted;
      border-color: $primary;
    }
  }

  input[type="radio"]:checked + label {
    &::after {
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }

  input[type="radio"]:checked + label::before {
    border-color: $primary;
  }

  input[type="radio"]:disabled + label {
    &::before {
      cursor: not-allowed;
    }
  }

  &.radio-primary2 {
    input[type="radio"] + label {
      &::before {
        background-color: $primary;
      }

      &::after {
        background-color: $white;
      }
    }

    input[type="radio"]:checked + label {
      &::before {
        border-color: $primary;
      }

      &::after {
        border-color: $primary;
      }
    }
  }

  &.radio-secondary2 {
    input[type="radio"] + label {
      &::before {
        background-color: $secondary;
      }

      &::after {
        background-color: $white;
      }
    }

    input[type="radio"]:checked + label {
      &::before {
        border-color: $secondary;
      }

      &::after {
        border-color: $secondary;
      }
    }
  }

  &.radio-success2 {
    input[type="radio"] + label {
      &::before {
        background-color: $success;
      }

      &::after {
        background-color: $white;
      }
    }

    input[type="radio"]:checked + label {
      &::before {
        border-color: $success;
      }

      &::after {
        border-color: $success;
      }
    }
  }

  &.radio-danger2 {
    input[type="radio"] + label {
      &::before {
        background-color: $danger;
      }

      &::after {
        background-color: $white;
      }
    }

    input[type="radio"]:checked + label {
      &::before {
        border-color: $danger;
      }

      &::after {
        border-color: $danger;
      }
    }
  }

  &.radio-warning2 {
    input[type="radio"] + label {
      &::before {
        background-color: $warning;
      }

      &::after {
        background-color: $white;
      }
    }

    input[type="radio"]:checked + label {
      &::before {
        border-color: $warning;
      }

      &::after {
        border-color: $warning;
      }
    }
  }

  &.radio-info2 {
    input[type="radio"] + label {
      &::before {
        background-color: $info;
      }

      &::after {
        background-color: $white;
      }
    }

    input[type="radio"]:checked + label {
      &::before {
        border-color: $info;
      }

      &::after {
        border-color: $info;
      }
    }
  }

  &.radio-dark2 {
    input[type="radio"] + label {
      &::before {
        background-color: $dark;
      }

      &::after {
        background-color: $white;
      }
    }

    input[type="radio"]:checked + label {
      &::before {
        border-color: $dark;
      }

      &::after {
        border-color: $dark;
      }
    }
  }

  &.radio-purple2 {
    input[type="radio"] + label {
      &::before {
        background-color: $purple;
      }

      &::after {
        background-color: $white;
      }
    }

    input[type="radio"]:checked + label {
      &::before {
        border-color: $purple;
      }

      &::after {
        border-color: $purple;
      }
    }
  }

  &.radio-pink2 {
    input[type="radio"] + label {
      &::before {
        background-color: $pink;
      }

      &::after {
        background-color: $white;
      }
    }

    input[type="radio"]:checked + label {
      &::before {
        border-color: $pink;
      }

      &::after {
        border-color: $pink;
      }
    }
  }
}


