//
// Charts
// 

.chart-demo {
  height: 370px;
  margin: 20px auto;
}

/* Sparkline chart */
.jqstooltip {
  width: auto !important;
  height: auto !important;
  box-sizing: content-box;
  background-color: $white !important;
  padding: 5px 10px !important;
  border-radius: 3px;
  border-color: $white !important;
  display: inline-block !important;
  box-shadow: 0 -3px 31px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
}

.jqsfield {
  font-size: 12px !important;
  line-height: 18px !important;
  color: $dark !important;
}

.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.chart.chart-widget-pie {
  margin-top: 5px;
  margin-bottom: 5px;
}

.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;

  &:after {
    content: '%';
    margin-left: 0.1em;
    font-size: .8em;
  }
}


/* Morris chart */
.morris-chart {
  text {
    font-family: $font-secondary !important;
    color: $gray-500;
  }
}

.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 10px 12px;
  background: $light;
  border: none;
  font-family: $font-secondary;
  box-shadow: 0 5px 25px 5px rgba($dark, .14);

  .morris-hover-point {
    line-height: 28px;
  }

  .morris-hover-row-label {
    background-color: $primary;
    color: $white;
    padding: 4px 20px;
    border-radius: 5px 5px 0 0;
    margin: -10px -12px 10px;
  }
}

/* Flot chart */
#flotTip {
  padding: 8px 12px;
  background-color: $card-bg;
  z-index: 100;
  color: $dark;
  box-shadow: 0 5px 25px 5px rgba($dark, .14);
  border-radius: 1px;
}

.flot-chart {
  .flot-y-axis,
  .flot-x-axis {
    .flot-tick-label {
      transform: translateY(2px);
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;
      font-family: $font-secondary;
      letter-spacing: .5px;
      fill: $gray-500;
    }
  }
}


/* Chartist chart */
.ct-golden-section:before {
  float: none;
}

.ct-chart {
  height: 300px;
}

.ct-grid {
  stroke: rgba($dark, .09);
  stroke-width: 2px;
  stroke-dasharray: 3px;
}

.ct-chart .ct-label {
  fill: $text-muted;
  color: $text-muted;
  font-size: 14px;
  line-height: 1;
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: $white;
  fill: $white;
  font-size: 16px;
}

.ct-chart .ct-series.ct-series-a .ct-slice-donut,
.ct-chart .ct-series.ct-series-b .ct-bar {
  stroke: $gray-300;
}

.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point {
  stroke: $gray-400;
}

.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: $secondary;
}

.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut,
.ct-chart .ct-series.ct-series-a .ct-bar {
  stroke: $primary;
}

.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-line {
  stroke: $warning;
}

.ct-chart .ct-series.ct-series-c .ct-bar {
  stroke: $gray-100;
}

.ct-chart .ct-series.ct-series-e .ct-slice-donut,
.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point {
  stroke: $success;
}

.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: $warning;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point {
  stroke: $pink;
}


.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point {
  stroke: $purple;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point {
  stroke: darken($danger, 10%);
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: $primary;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: $secondary;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: $gray-300;
}


.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 10px;
  padding: 2px 10px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  background: $dark;
  color: $white;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

#donut-chart #donut-chart-container .legend {
  position: relative;
  margin-right: -50px;
  top: -50px;
}

// /*== Apex Chart ==*/

.apexcharts-gridlines-horizontal,
.apexcharts-gridlines-vertical,
.apexcharts-grid,
.apexcharts-gridline,
.apexcharts-xaxis-tick,
.apexcharts-xaxis line,
.apexcharts-gridline line {
  pointer-events: none;
  stroke: $apex-grid-color;
}

.apexcharts-radar-series line,
.apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-radar-series path, .apexcharts-radar-series polygon {
  pointer-events: none;
  stroke: $apex-grid-color-2;
}

.apexcharts-legend-text {
  color: $gray-500 !important;
  font-family: $font-secondary !important
}

.apexcharts-pie-label {
  fill: $white !important
}

.apexcharts-datalabels text,
.apexcharts-data-labels,
.apexcharts-xaxis text,
.apexcharts-yaxis text {
  font-family: $font-secondary !important;
  fill: $gray-500;
}

.apexcharts-yaxis-annotation-label {
  color: $gray-500 !important;
}

.apexcharts-point-annotations text,
.apexcharts-xaxis-annotations text,
.apexcharts-yaxis-annotations text {
  fill: $white;
}

.britechart,
.tick text {
  font-family: $font-secondary;
  font-size: .75rem
}

.extended-x-line,
.extended-y-line,
.horizontal-grid-line,
.vertical-grid-line {
  stroke: $gray-300;
}

.bar-chart .percentage-label,
.donut-text,
.legend-entry-name,
.legend-entry-value,
.tick text {
  fill: $gray-500;
}

.apex-charts {
  min-height: 10px !important;
}

.apexcharts-tooltip-title {
  background: linear-gradient(14deg, $primary 0%, rgba($primary, 0.6)) !important;
  color: $white;
  border-color: $gray-100 !important;
}

.dash-apex-chart {
  .apexcharts-toolbar {
    display: none !important;
  }
}

.apexcharts-tooltip {
  background: $gray-100 !important;
  border-color: $gray-100 !important;
  white-space: normal !important;
}


.apexcharts-xaxistooltip {
  background-color: $body-bg !important;
  border-color: $body-bg !important;
  color: $gray-700 !important;
}

.apexcharts-xaxistooltip-top:before,
.apexcharts-xaxistooltip-top:after {
  border-top-color: $body-bg !important;
}

.apexcharts-xaxistooltip-bottom:before,
.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: $body-bg !important;
}

.apexcharts-xaxistooltip:before,
.apexcharts-xaxistooltip:after {
  border-color: $body-bg;
}

.apexcharts-title-text,
.apexcharts-datalabel-label {
  color: $gray-700;
}

.apexcharts-datalabel-value {
  color: $gray-100;
}

.apexcharts-legend-marker {
  margin-right: 5px;
  vertical-align: middle;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
  .apexcharts-yaxis-texts-g,
  .apexcharts-xaxis-texts-g {
    .apexcharts-text.apexcharts-yaxis-label,
    .apexcharts-text.apexcharts-xaxis-label {
      font-weight: 500;
    }
  }
}

.apexcharts-datalabels-group {
  .apexcharts-text {
    &.apexcharts-datalabel-value,
    &.apexcharts-datalabel-label {
      fill: $gray-500;
      color: $gray-500;
    }
  }
}

/*clock*/

.superpose {
  color: $gray-200;
  height: 350px;
  width: 100%;

  .superclock {
    position: relative;
    width: 300px;
    margin: auto;

    .superclock1 {
      position: absolute;
      left: 10px;
      top: 10px;
    }

    .superclock2 {
      position: absolute;
      left: 60px;
      top: 60px;
    }

    .superclock3 {
      position: absolute;
      left: 110px;
      top: 110px;
    }
  }
}

.drop-shadow {
  filter: drop-shadow(0px 8px 4px $gray-100);
}