// 
// _badge.scss
// 
.badge-success,
.badge-orange {
  color: $white;
}



  