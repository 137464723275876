//
// helpdesk
// 

.happiness-score {
  position: absolute;
  bottom: 63%;
  left: 0;
  right: 0;
  text-align: center;
}

.help-activity-height {
  height: 365px !important;
}

.timer-data {
  .icon-info {
    i {
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 26px;
      z-index: 5;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 30px;
  }
}
