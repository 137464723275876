
.form-wizard-wrapper {
  label {
    font-size: 13px;
    text-align: right;
  }
}

.wizard > .content > .body input {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid $border_2;
  transition: border-color 0s ease-out;

  &:focus {
    box-shadow: none;
    border-color: $primary;
  }
}

.wizard.vertical > .steps {
  width: auto;
}

.wizard > .steps > ul > li {
  width: auto;
  margin-bottom: 10px;
}

.wizard > .steps a,
.wizard > .steps a:active,
.wizard > .steps a:hover {
  margin: 3px;
  padding: 0;
  border-radius: 50px;
}

.wizard > .steps .current a,
.wizard > .steps .current a:active,
.wizard > .steps .current a:hover {
  background: linear-gradient(14deg, $primary 0%, rgba($primary, 0.6));
  color: $white;
  padding: 2px 20px 2px 2px;
  box-shadow: 0px 3px 10px 0 rgba($primary, 0.4);
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:active,
.wizard > .steps .disabled a:hover,
.wizard > .steps .done a,
.wizard > .steps .done a:active,
.wizard > .steps .done a:hover {
  background-color: rgba($primary, 0.1);
  color: $primary;
  padding: 0 20px 0 0;
  box-shadow: 0px 0px 0px 2.25px rgba($primary, 0.5);
}

.wizard > .steps .current a .number,
.wizard > .steps .current a:active .number,
.wizard > .steps .current a:hover .number {
  border: 2px solid $white;
}

.wizard > .steps .disabled a .number,
.wizard > .steps .disabled a:active .number,
.wizard > .steps .disabled a:hover .number,
.wizard > .steps .done a .number,
.wizard > .steps .done a:active .number,
.wizard > .steps .done a:hover .number {
  border-color: $primary;
}

.wizard > .content {
  background-color: transparent;
  margin: 0 5px;
  border-radius: 0;
  min-height: 150px
}

#form-horizontal fieldset {
  margin-top: 20px;
}

.wizard > .content > .body {
  width: 100%;
  height: 100%;
  padding: 15px 0 0;
  position: static;
}

.wizard > .steps .number {
  font-size: 16px;
  padding: 5px;
  border-radius: 50%;
  border: 2px solid $white;
  width: 38px;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  margin-right: 10px;
  background-color: rgba($primary, 0.15);
}

.wizard > .actions,
.wizard.vertical > .actions {
  margin-top: 20px;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:active,
.wizard > .actions .disabled a:hover {
  opacity: .65;
  background: linear-gradient(14deg, $primary 0%, rgba($primary, 0.6));
  color: $white;
  cursor: not-allowed;
}

.wizard > .actions a,
.wizard > .actions a:active,
.wizard > .actions a:hover {
  background: linear-gradient(14deg, $primary 0%, rgba($primary, 0.6));
  border-radius: 4px;
  padding: 8px 15px;
}

@media (max-width: 768px) {
  .wizard > .steps > ul > li {
    width: 50%;
  }
  .form-wizard-wrapper {
    label {
      text-align: left;
    }
  }
}

@media (max-width: 520px) {
  .wizard > .steps > ul > li {
    width: 100%;
  }
}
