@import '~izitoast/dist/css/iziToast.min.css';

.cursor-pointer {
  cursor: pointer;
}

.custom-swal2-htmlContainer {
  color: initial !important;
  font-size: inherit !important;
  font-weight: initial !important;
  line-height: initial !important;
  text-align: initial !important;
}

.hover-toggle {
  .hovered {
    display: none;
  }

  &:hover {
    .default {
      display: none;
    }

    .hovered {
      display: initial;
    }
  }
}

.object-fit-contain {
  object-fit: contain;
}
