// 
// _ribbons.scss
// 

//
// Ribbons
// 

.ribbon1 {
  position: absolute;
  top: -6.1px;
  right: 10px;

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 35px solid transparent;
    border-right: 35px solid transparent;
    border-top: 10px solid;
  }

  span {
    display: block;
    padding: 8px 8px 4px;
    border-top-right-radius: 8px;
    width: 70px;

    &:before,
    &:after {
      position: absolute;
      content: "";
    }

    &:before {
      height: 6px;
      width: 4px;
      left: -4px;
      top: 0;
    }

    &:after {
      height: 6px;
      width: 6px;
      left: -6px;
      top: 0;
      border-radius: 8px 8px 0 0;
    }

    //rib-primary
    &.rib1-primary:before,
    &.rib1-primary {
      background: $primary;
    }

    &.rib1-primary:after {
      background: darken($primary, 10%);
    }

    //rib-secondary
    &.rib1-secondary:before,
    &.rib1-secondary {
      background: $secondary;
    }

    &.rib1-secondary:after {
      background: darken($secondary, 15%);
    }

    //rib-success
    &.rib1-success:before,
    &.rib1-success {
      background: $success;
    }

    &.rib1-success:after {
      background: darken($success, 15%);
    }

    //rib-pink
    &.rib1-pink:before,
    &.rib1-pink {
      background: $pink;
    }

    &.rib1-pink:after {
      background: darken($pink, 15%);
    }

    //rib-purple
    &.rib1-purple:before,
    &.rib1-purple {
      background: $purple;
    }

    &.rib1-purple:after {
      background: darken($purple, 15%);
    }

    //rib-warning
    &.rib1-warning:before,
    &.rib1-warning {
      background: $warning;
    }

    &.rib1-warning:after {
      background: darken($warning, 15%);
    }

    //rib-danger
    &.rib1-danger:before,
    &.rib1-danger {
      background: $danger;
    }

    &.rib1-danger:after {
      background: darken($danger, 15%);
    }

    //rib-info
    &.rib1-info:before,
    &.rib1-info {
      background: $info;
    }

    &.rib1-info:after {
      background: darken($info, 15%);
    }

    //rib-light
    &.rib1-light:before,
    &.rib1-light {
      background: $light;
    }

    &.rib1-light:after {
      background: darken($light, 15%);
    }

    //rib-dark
    &.rib1-dark:before,
    &.rib1-dark {
      background: $dark;
    }

    &.rib1-danger:after {
      background: darken($dark, 15%);
    }
  }


  //rib-primary
  &.rib1-primary:after {
    border-top-color: $primary;
  }

  //rib-secondary
  &.rib1-secondary:after {
    border-top-color: $secondary;
  }

  //rib-success
  &.rib1-success:after {
    border-top-color: $success;
  }

  //rib-warning
  &.rib1-warning:after {
    border-top-color: $warning;
  }

  //rib-danger
  &.rib1-danger:after {
    border-top-color: $danger;
  }

  //rib-info
  &.rib1-info:after {
    border-top-color: $info;
  }

  //rib-light
  &.rib1-light:after {
    border-top-color: $light;
  }

  //rib-dark
  &.rib1-dark:after {
    border-top-color: $dark;
  }

  //rib-pink
  &.rib1-pink:after {
    border-top-color: $pink;
  }

  //rib-purple
  &.rib1-purple:after {
    border-top-color: $purple;
  }
}

//Ribbon2

.ribbon2 {
  width: 30px;
  padding: 8px 0;
  position: absolute;
  top: -6px;
  right: 12px;
  text-align: center;
  border-top-left-radius: 3px;

  &:before,
  &:after {
    content: "";
    position: absolute;
  }

  &:before {
    height: 0;
    width: 0;
    right: -5.5px;
    top: 0.1px;
    border-right: 6px solid transparent;
  }

  &:after {
    height: 0;
    width: 0;
    bottom: -18px;
    left: 0;
    border-bottom: 18px solid transparent;
  }

  //rib2-primary
  &.rib2-primary {
    background: $primary;

    &:before {
      border-bottom: 6px solid darken($primary, 15%);
    }

    &:after {
      border-left: 15px solid $primary;
      border-right: 15px solid $primary;
    }
  }

  //rib2-secondary
  &.rib2-secondary {
    background: $secondary;

    &:before {
      border-bottom: 6px solid darken($secondary, 15%);
    }

    &:after {
      border-left: 15px solid $secondary;
      border-right: 15px solid $secondary;
    }
  }

  //rib2-success
  &.rib2-success {
    background: $success;

    &:before {
      border-bottom: 6px solid darken($success, 15%);
    }

    &:after {
      border-left: 15px solid $success;
      border-right: 15px solid $success;
    }
  }

  //rib2-warning
  &.rib2-warning {
    background: $warning;

    &:before {
      border-bottom: 6px solid darken($warning, 15%);
    }

    &:after {
      border-left: 15px solid $warning;
      border-right: 15px solid $warning;
    }
  }
}

// ribbon-3

.ribbon3 {
  text-align: center;
  width: 100px;
  height: 30px;
  line-height: 32px;
  padding-right: 15px;
  position: absolute;
  right: -8px;
  top: 10px;

  &:before,
  &:after {
    content: "";
    position: absolute;
  }

  &:before {
    height: 0;
    width: 0;
    bottom: -8.5px;
    right: 0.1px;
    border-right: 9px solid transparent;
  }

  &:after {
    height: 0;
    width: 0;
    left: -14.5px;
    border-bottom: 15px solid transparent;
    border-top: 15px solid transparent;
  }

  &.rib3-primary {
    background: $primary;

    &:before {
      border-top: 9px solid darken($primary, 15%);
    }

    &:after {
      border-right: 15px solid $primary;
    }
  }

  &.rib3-secondary {
    background: $secondary;

    &:before {
      border-top: 9px solid darken($secondary, 15%);
    }

    &:after {
      border-right: 15px solid $secondary;
    }
  }

  &.rib3-warning {
    background: $warning;

    &:before {
      border-top: 9px solid darken($warning, 15%);
    }

    &:after {
      border-right: 15px solid $warning;
    }
  }

  &.rib3-success {
    background: $success;

    &:before {
      border-top: 9px solid darken($success, 15%);
    }

    &:after {
      border-right: 15px solid $success;
    }
  }
}

//ribbon-4
.ribbon4 {
  width: 100%;
  height: 188px;
  position: absolute;
  top: -8px;
  left: 8px;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    position: absolute;
  }

  &:before {
    width: 40px;
    height: 8px;
    right: 48px;
    border-radius: 8px 8px 0px 0px;
  }

  &:after {
    width: 8px;
    height: 40px;
    right: 0px;
    top: 48px;
    border-radius: 0px 8px 8px 0px;
  }

  .ribbon4-band {
    width: 170px;
    height: 24px;
    line-height: 24px;
    position: absolute;
    top: 16px;
    right: -50px;
    z-index: 2;
    overflow: hidden;
    transform: rotate(45deg);
    border: 1px dashed;
    text-align: center;
    font-size: 12px;

    &.ribbon4-band-primary {
      box-shadow: 0 0 0 3px $primary, 0px 21px 5px -18px rgba($gray-700, .6);
      background: $primary;
    }

    &.ribbon4-band-secondary {
      box-shadow: 0 0 0 3px $secondary, 0px 21px 5px -18px rgba($gray-700, .6);
      background: $secondary;
    }

    &.ribbon4-band-success {
      box-shadow: 0 0 0 3px $success, 0px 21px 5px -18px rgba($gray-700, .6);
      background: $success;
    }

    &.ribbon4-band-warning {
      box-shadow: 0 0 0 3px $warning, 0px 21px 5px -18px rgba($gray-700, .6);
      background: $warning;
    }
  }

  &.rib4-primary {
    &:before,
    &:after {
      background: darken($primary, 10%);
    }
  }

  &.rib4-secondary {
    &:before,
    &:after {
      background: darken($secondary, 10%);
    }
  }

  &.rib4-success {
    &:before,
    &:after {
      background: darken($success, 10%);
    }
  }

  &.rib4-warning {
    &:before,
    &:after {
      background: darken($warning, 10%);
    }
  }
}

