/*
Template Name: Metrica - Admin & Dashboard Template
Author: Mannatthemes
Email: mannat.themes@gmail.com
File: Leftbar
*/


.left-sidenav {
  max-width: $leftbar-width;
  background-color: $bg-leftbar;
  //min-height: 100vh;
  //custom
  height: 100vh;
  display: flex;
  flex-direction: column;
  //
  box-shadow: $shadow;
  transition: .3s;
  position: fixed;
  z-index: 1001;

  .leftbar-profile {
    display: inline-block;
    background-color: $light-alt;

    h5 {
      color: $menu-item-color;
    }

    p {
      color: lighten($menu-item-color, 5%);
    }

    .leftbar-user {
      &:after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 40px;
        width: 6px;
        height: 6px;
        background-color: $gray-500;
        box-shadow: 0 0 0 2px rgba($card-bg, 0.8);
        border-radius: 50%;
      }

      &.online:after {
        background-color: $success;
      }
    }
  }

  .topbar-left {
    background-color: $brand-light-bg;
    float: left;
    text-align: center;
    height: $topbar-height;
    position: relative;
    width: $topbar-left-width;
    z-index: 1;

    .logo {
      line-height: $topbar-height;

      .logo-sm {
        height: 30px;
      }

      .logo-lg {
        height: 30px;
        margin-left: 2px;
        display: $logo-dark;

        &.logo-light {
          display: $logo-light;
        }
      }
    }

  }
}

.page-wrapper {
  flex: 1;
  padding: 0;
  display: flex;

  .page-content {
    width: calc(100% - 270px);
    margin-left: auto;
    position: relative;
    min-height: 100vh;
    padding: 0 15px 60px 15px;
    margin-top: $topbar-height;
  }
}

.left-sidenav-menu {
  margin-bottom: 0;
  padding: 20px;
  //height: 800px !important;
  //custom
  overflow: auto;
  //

  .menu-label {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .5px;
    color: $gray-500;
    padding: 8px 0;
  }

  .vertical-menu-icon {
    width: 22px;
    height: 22px;
    margin-right: 6px;

    &.icon-dual-vertical {
      color: $sidenav-icon-color;
      fill: rgba($sidenav-icon-color, .12);
    }
  }

  li {
    list-style: none;
    display: block;
    width: 100%;
    margin-top: 6px;

    > a {
      display: block;
      padding: 12px 18px;
      color: $menu-item-color;
      font-size: 13px;
      font-weight: 500;
      transition: all 0.3s ease-out;

      &:hover {
        color: $menu-sub-item-hover-color;

        i {
          color: $leftbar-icon-hover-color;
        }
      }

      i {
        width: 25px;
        display: inline-block;
        font-size: 16px;
        opacity: 0.9;
        color: $leftbar-icon-color;
        margin-right: 5px;

        &.ti-control-record {
          font-size: 8px;
          vertical-align: middle;
          margin-right: 0;
        }
      }

      span {
        i {
          color: $menu-arrow-color;
        }
      }
    }

    ul {
      padding: 0 0 0 25px;

      li {
        > a {
          padding: 10px 22px;
          color: $menu-sub-item-color;
          border-left: none;

          &:hover {
            color: $menu-sub-item-hover-color;

            i {
              color: $primary;
            }
          }
        }
      }
    }

    &.mm-active {
      .menu-arrow {
        i {
          //custom
          transform: rotate(90deg);
          //

          //&:before {
          //  content: "\F140";
          //}
        }
      }

      .mm-active a .menu-arrow.left-has-menu {
        i {
          &:before {
            content: "\F140";
          }
        }
      }

      .menu-arrow.left-has-menu {
        i {
          &:before {
            content: "\F142";
          }
        }
      }

      li a {
        menu-arrow.left-has-menu {
          i {
            &:before {
              content: "\F142";
            }
          }
        }
      }

      .mm-active {
        > a {
          color: $menu-sub-item-active-color;
          background: $bg-leftbar;
          box-shadow: 3px 0 20px 0 $gray-200;

          &.active {
            color: $menu-sub-item-active-color;
            background-color: transparent;
          }

          i {
            color: $primary;
          }
        }

        .menu-arrow.left-has-menu i:before {
          content: "\F140";
        }

        .mm-show {
          li {
            a.active {
              color: $primary;
            }
          }
        }
      }

      > a {
        color: $menu-item-active-color;
        border-radius: 4px;

        .vertical-menu-icon {
          &.icon-dual-vertical {
            color: $primary;
            fill: rgba($primary, .12);
          }
        }
      }

      .nav-item.active {
        a.nav-link.active {
          background-color: transparent;
          color: $primary;

          i {
            color: $menu-item-hover-color;
          }
        }
      }

    }

  }


  .menu-arrow {
    float: right;
    margin-top: -1px;

    i {
      width: 15px;
    }
  }
}

@media (max-width: 1024px) {
  body {
    display: block !important;

    &.enlarge-menu.enlarge-menu-all {
      .left-sidenav {
        width: 0;
      }
    }
  }
  .left-sidenav {
    position: fixed;
    overflow-y: auto;
    z-index: 10;
    bottom: 0;
    top: 0;
    margin-top: 0;
  }
  .page-content {
    min-height: 100vh;
    width: 100%;
    padding: 0 0 50px 0;
  }
}


// Collapsed

body {
  &.enlarge-menu {
    overflow: visible !important;


    .left-sidenav {
      width: 70px;
      padding: 0;
      z-index: 5;
      transform: none;

      .topbar-left {
        position: fixed;
        width: 70px;
        height: 70px;
        z-index: 6;

        .logo {
          padding: 0 5px;
          line-height: 0;

          .logo-lg {
            display: none;
          }

          .logo-sm {
            display: block !important;
            line-height: 70px;
            margin: 0 auto;
          }
        }
      }

      &.leftbar-fixed {
        position: absolute;
      }

      .leftbar-profile {
        display: none;
      }

      .left-sidenav-menu {
        padding-top: 0;
        margin-top: 70px;
        padding: 0;
        //custom
        overflow: visible !important;
        //

        .badge,
        .collapse.in,
        .menu-label {
          display: none;
        }

        .nav.collapse {
          height: inherit !important;
        }

        li {
          position: relative;

          &:hover {
            ul {
              left: 70px;
              position: absolute;
              width: 200px;
              padding: 5px 0;
              z-index: 9999;
              background: $bg-leftbar;
              height: auto !important;
              display: block;
              box-shadow: 0px 2px 3px 0.25px $gray-300;

              li {
                .nav-second-level {
                  display: none;
                }

                &:hover {
                  .nav-second-level {
                    display: block !important;
                    left: 100%;
                    top: 0;
                  }
                }
              }
            }
          }

          > a {
            color: $menu-item-dark-color;
          }
        }

        .leftbar-menu-item {
          position: relative;
          white-space: nowrap;

          .menu-link {
            padding: 12px 22px;
            min-height: 56px;
            margin: 0;
            position: relative;
            border-radius: 0;
            display: grid;

            &:active,
            &:hover {
              i {
                color: $white;
              }
            }

            i {
              line-height: 26px;
              margin: 0 3px;
            }

            span {
              padding-left: 40px;
              display: none;
            }

            &.mm-active {
              border-radius: 0;
            }
          }

          &:hover {
            .menu-link {
              position: relative;
              color: $white;
              background: $primary;
              border-radius: 0;
              width: calc(200px + 70px);
              box-shadow: none;
              display: flex;
              align-items: center;
              transition: none;

              span {
                display: inline;

                &.menu-arrow,
                &.badge {
                  display: none;
                }

              }

              .icon-dual-vertical {
                color: $white;
                fill: rgba($white, 0.12);
              }
            }

            > ul {
              a {
                padding: 8px 20px;
                position: relative;
                width: 200px;
                margin-left: 0;
                color: $menu-item-color;
                font-weight: 400;

                &:hover {
                  color: $primary;
                }
              }
            }
          }
        }

        li:hover > a i {
          color: $primary;
        }
      }

      .slimScrollDiv {
        overflow: visible !important;

        .slimscroll {
          overflow: visible !important;
        }
      }
    }


    .metismenu {
      li.mm-active > a {
        background: $bg-leftbar;
      }


      .mm-collapsing {
        display: none !important;
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: 0;
        transition-property: height, visibility;
      }

      .mm-collapse.mm-show {
        display: none;
        height: 0 !important;
      }
    }

    .page-wrapper {
      //min-height: 1300px !important;
    }
  }
}
