//
// Project
// 


//Dashboard


.project-dash-activity {
  min-height: 535px;
}

.project-budget-chart {
  height: 273px;
}

.weather-btn-icon i {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  background-color: $primary;
  color: $white;
  display: inline-block;
  margin: 0 -4px 0 14px;
}

.icon-info {
  i {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 26px;
    z-index: 5;
  }
}


.c-progress-steps {
  margin: 0;
  list-style-type: none;
  font-family: $font-primary;

  li {
    position: relative;
    font-size: 13px;
    color: $gray-400;
    padding: 16px 0 0 36px;

    a {
      color: inherit;
    }

    &.done {
      color: $info;

      span {
        background-color: rgba($info, .09);
      }

      &:before {
        color: $info;
        content: "\f058";
        width: 30px;
        height: 30px;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        border: 2px solid $info;
      }
    }

    &.current {
      color: $primary;
      font-weight: 500;

      &:before {
        color: $primary;
        content: "\f192";
        width: 30px;
        height: 30px;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        border: 2px solid $primary;
      }
    }

    &:before {
      position: absolute;
      left: 0;
      font-family: 'Font Awesome 5 Free' !important;
      font-weight: 600;
      font-size: 18px;
      background-color: $white;
      content: "\f111";
      width: 30px;
      height: 30px;
      display: inline-block;
      text-align: center;
      border-radius: 50%;
      border: 2px solid $gray-300;

      @media all and (max-width: 600px) {
        top: calc(50% - 8px);
        font-size: 16px;
      }
    }
  }

  @media all and (min-width: 600px) {
    display: table;
    list-style-type: none;
    margin: 20px auto;
    padding: 0;
    table-layout: fixed;
    width: 100%;

    li {
      display: table-cell;
      text-align: center;
      padding: 0;
      padding-bottom: 34px;
      white-space: nowrap;
      position: relative;
      border-left-width: 0;
      border-bottom-width: 4px;
      border-bottom-style: solid;
      border-bottom-color: $gray-300;

      &.done {
        border-bottom-color: $info;
      }

      &.current {
        color: $white;
        border-bottom-color: $primary;

        span {
          background: linear-gradient(14deg, $primary 0%, rgba($primary, .6));
          box-shadow: 0 7px 14px 0 rgba($primary, .5);
        }

        &:before {
          color: $primary;
          content: "\f192";
        }
      }

      &:before {
        bottom: -15px;
        left: 50%;
        margin-left: -16px;
      }

      span {
        background-color: rgba($info, .1);
        border-radius: 3px;
        padding: 6px;
        box-shadow: 0px 0px 5px 0.25px lighten($gray-300, 6%);
      }
    }
  }
}

.workload-chart {
  height: 210px;
}


/*== todo list ==*/

.todo-list {
  height: 200px !important;

  .todo-box {
    i {
      position: absolute;
      right: 20px;
      z-index: 10;
      font-size: 16px;
      margin-top: 6px;
      color: $gray-400;
    }

    .todo-task {
      padding: 4px 0;

      label {
        display: inline-block;
        margin-bottom: .5rem;
      }

      .ckbox {
        font-weight: normal;
        position: relative;
        display: block;
        line-height: 25px;

        span {
          padding-left: 16px;
          color: $table-color;

          &:before {
            content: '';
            width: 20px;
            height: 20px;
            background-color: transparent;
            border: 2px solid $gray-300;
            top: 2px;
            left: 0;
            border-radius: 5px;
            position: absolute;
          }

          &:after {
            top: 1px;
            left: 0;
            width: 20px;
            height: 20px;
            content: "\F12C";
            font: normal normal normal 24px/1 "Material Design Icons";
            font-size: 14px;
            text-align: center;
            color: $primary;
            background-color: lighten($primary, 40%);
            line-height: 20px;
            display: none;
            border-radius: 5px;
            position: absolute;
          }
        }

        input[type='checkbox'] {
          opacity: 0;
          margin: 0 5px 0 0;
        }

        input[type='checkbox']:checked + span:after {
          display: block;
        }
      }

      input:checked + span {
        text-decoration: line-through;
      }
    }
  }

  .form-control {
    color: $dark;
    background: $gray-300;
    border: 1px solid transparent;

    &:focus {
      border-color: transparent;
      background: $gray-300;
      box-shadow: none;
    }
  }
}

//  Clients

.client-card {
  .client-name {
    color: $gray-700;
  }
}

// Calendar

.calendar-event {
  .event-name {
    h3 {
      font-size: 14px;
      color: $gray-700;
    }
  }
}

// Invoie

.project-invoice {
  .table {
    tr {
      td {
        h5 {
          color: $gray-700;
        }
      }
    }
  }
}

// Team

.team-card {
  .user-img {
    height: 80px;
  }

  .online-circle {
    position: absolute;
    right: 0;
    top: 118px;
    left: 44px;
  }

  .team-leader {
    font-size: 16px;
    color: $gray-700;
  }
}

.language-skill li {
  span {
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 4px 8px;
    background-color: $gray-200;
    color: $dark;
    font-size: 11px;
  }
}

// Projects-card

.project-card {
  font-family: $font-secondary;

  .project-title {
    font-size: 20px;
    color: $gray-700;
    font-weight: 600;
  }
}

// Task Box

.task-box {
  .task-priority-icon {
    i {
      border: 5px solid $body-bg;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      left: -5px;
    }
  }
}

// bar chart shadow

.drop-shadow-bar {
  filter: drop-shadow(6px 6px 8px rgba($gray-400, 0.65));
}

// Step bar

ul.steppedprogress {
  counter-reset: step;
  display: flex;
  margin: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  z-index: 1;

  li {
    list-style-type: none;
    color: $gray-700;
    line-height: normal;
    text-transform: uppercase;
    flex: 1;
    font-size: 13px;
    position: relative;
    padding: 0 2%;
    font-weight: 600;

    &:before {
      content: counter(step);
      counter-increment: step;
      width: 30px;
      height: 30px;
      line-height: 24px;
      display: block;
      font-size: 14px;
      color: $gray-700;
      background: $light;
      border-radius: 50%;
      margin: 0 auto 10px;
      position: relative;
      z-index: 1;
      border: 3px solid $card-bg;
      box-shadow: 0px 0px 0px 1.25px $light;
    }

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background: $light;
      position: absolute;
      left: -50%;
      top: 15px;
      z-index: -1
    }

    &.complete {
      &:before {
        background: $success;
        color: $white;
        border: 3px solid $card-bg;
        box-shadow: 0px 0px 0px 1.25px $success;
      }

      &.continuous {
        &:before {
          background: $primary;
          color: $white;
          border: 3px solid $card-bg;
          box-shadow: 0px 0px 0px 1.25px $primary;
        }

        span {
          color: $primary;
        }
      }

      &.finish {
        &:before {
          background: $danger;
          color: $white;
          border: 3px solid $card-bg;
          box-shadow: 0px 0px 0px 1.25px $danger;
          content: "\F12C";
          font: normal normal normal 24px/1 "Material Design Icons";
        }

        span {
          color: $danger;
        }
      }

      &:after {
        background: rgba($success, 0.6)
      }
    }
  }

  li:first-child:after {
    content: none
  }
}


@media (max-width: 480px) {
  ul.steppedprogress {
    display: block;

    li {
      flex: none;
      clear: both;
      text-align: left;
      padding: 0;
      margin-left: 0;
      min-height: 2.2em;

      span {
        white-space: nowrap
      }

      &:before {
        float: none;
        display: inline-block;
        margin-right: 10px;
        text-align: center;
        margin-left: 0
      }

      &:after {
        content: '';
        width: 6px;
        height: 100%;
        position: absolute;
        left: 12px;
        top: -50%;
        z-index: -1
      }
    }
  }
}