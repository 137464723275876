// 
// _type.scss
// 

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-34 {
  font-size: 34px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-40 {
  font-size: 40px !important;
}


.text-dark-alt {
  color: lighten($dark, 10%) !important;
}