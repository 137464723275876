//
// Nastable
// 


.custom-dd {
  max-width: 100%;
}

.dd3-content,
.dd-handle {
  height: 40px;
  padding: 8px 10px;
  border: 1px solid $border_2;
  background-color: $card-bg;
  color: $gray-700;
  border-radius: 3px;
  font-weight: normal;

  &:hover {
    background-color: lighten($gray-200, 2%);
    color: $primary;
  }
}

.dd-item > button {
  height: 25px;
  margin: 8px 0 0;
  font-size: 18px;

  &:before {
    color: $gray-700;
  }
}

.dd3-item {
  margin: 5px 0;

  .dd3-handle {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 30px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid $primary;
    background: $primary;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      background: lighten($primary, 5%);
    }

    &:before {
      content: "\f0c9";
      font-family: "Font Awesome 5 Free" !important;
      font-weight: 600;
      display: block;
      position: absolute;
      left: 0;
      top: 9px;
      width: 100%;
      text-align: center;
      text-indent: 0;
      color: $white;
      font-size: 14px;
    }
  }
}

.dd3-content-p {
  padding: 8px 10px 8px 40px;
}

.dd-empty,
.dd-placeholder {
  background: $body-bg;
  border: 1px dashed $border_2;
}