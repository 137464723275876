/*
Template Name: Metrica - Admin & Dashboard Template
Author: Mannatthemes
Version: 2.1.0
File: footer Css
*/


.footer {
  border-top: 1px solid $border_2;
  bottom: 0;
  padding: 20px;
  position: absolute;
  right: 0;
  left: 0;
  color: $gray-600;
}