//
// Pricing
// 


.pricingTable1 {
  .title1 {
    font-weight: 700;
    color: $gray-700;
    font-size: 20px;
    text-transform: capitalize;
  }

  .amount {
    display: block;
    position: relative;
    font-size: 36px;
    font-weight: 600;
    color: $gray-700;

    &.amount-border {
      &::after {
        content: '';
        position: absolute;
        height: 6px;
        background-color: rgba($warning, 0.6);
        top: 28px;
        width: 100%;
        right: 0;
        border-radius: 30px;
        transform: rotate(-5deg);
      }
    }
  }

  .pricing-content-2 {
    margin: 0 0 0px 0;
  }

  .pricingTable-signup:hover {
    background: linear-gradient(14deg, rgba($primary, 0.6) 0%, $primary);
    box-shadow: 0 1px 2px 0 rgba($gray-400, 0.5);
    color: $white;
  }

  .pricing-content-2 li {
    color: $gray-700;
    line-height: 40px;

    &::before {
      content: "\f00c" !important;
      font-family: 'Font Awesome 5 Free' !important;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      background-color: rgba($purple, 0.1);
      color: $purple !important;
      width: 26px;
      height: 26px;
      line-height: 26px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  .pricingTable-signup {
    display: block;
    padding: 14px 26px;
    background: linear-gradient(14deg, $primary 0%, rgba($primary, 0.6));
    border-radius: 52px;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    text-align: center;
    transition: all 300ms linear 0ms;
    box-shadow: 0 7px 14px 0 rgba($primary, 0.5);
  }
}

.pricingTable1 .a-animate-blink {
  -webkit-animation: a-animate-blink 1s step-start 0s infinite;
  -moz-animation: a-animate-blink 1s step-start 0s infinite;
  -ms-animation: a-animate-blink 1s step-start 0s infinite;
  -o-animation: a-animate-blink 1s step-start 0s infinite;
  animation: a-animate-blink 1s step-start 0s infinite;
  animation-fill-mode: initial;
}

@-webkit-keyframes a-animate-blink {
  50% {
    opacity: 0.0;
  }
}

@-moz-keyframes a-animate-blink {
  50% {
    opacity: 0.0;
  }
}

@-o-keyframes a-animate-blink {
  50% {
    opacity: 0.0;
  }
}

@keyframes a-animate-blink {
  50% {
    opacity: 0.0;
  }
}

// .custom-border{
//   height: 5px;
//   width: 100%;
//   background-color: $gray-200;
// }