//
// Invoice
// 


.invoice-head {
  border-bottom: 4px double $gray-400;

  .logo-lg.logo-light {
    display: $logo-light;
  }

  .logo-lg.logo-dark {
    display: $logo-dark;
  }

  .contact-detail {
    li {
      border-left: 2px solid $gray-400;

      i {
        font-size: 24px;
        color: $info;
        margin-bottom: 5px;
      }
    }
  }
}